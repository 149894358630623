<template>
  <div>
    
    <h2 class="text-2xl font-bold mb-5">{{ tab | sentenceCase }} Credit Card Loans</h2>
    <div class="grid grid-cols-2 mb-6">
      <div class="col-span-1 flex items-center">
        <tabs :tabs="tabs" v-model="tab" />
      </div>
      <!-- <div class="col-span-1 flex items-center">
        <div class="relative w-full">
          <input
            type="search"
            name="search"
            class="border border-solid border-blue-200 rounded placeholder-blue-200 outline-none pl-10 pr-2 py-2 text-14 w-full"
            placeholder="Search for user's name, company's name etc."
            v-model="searchQuery">
          <ion-icon name="search-outline" class="text-blue-200 absolute z-10 text-lg ml-3 left-0" style="top: 50%; transform: translateY(-50%);"></ion-icon>
        </div>
      </div> -->
    </div>

    <component :is="tab" />
  </div>
</template>

<script>
  export default {
    name: 'CreditCardLoansDisbursement',
    components: {
      personal: require('./Personal').default,
      corporate: require('./Corporate').default,
    },
    data() {
      return {
        searchQuery: '',
        tabs: [
          {name: 'personal', title: 'Personal'},
          {name: 'corporate', title: 'Corporate'},
        ],
        tab: 'personal'
      }
    },
  }
</script>